@use "../../../styles/mixins";
@use '../../../styles/variables.module' as *;
@use "../../../styles/colors";
@use '../../../styles/zIndex.module' as zIndex;

//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;

//** AdminModal title line-height
$line-height-base: 1.428571429 !default; // 20/14
$border-width: 1px !default;

// Padding applied to the modal body
$modal-inner-padding: 15px !default;

$modal-dialog-margin: 10px !default;
$modal-dialog-sm-up-margin-y: 30px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: #fff !default;
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-xs-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5) !default;
$modal-content-sm-up-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !default;

$modal-backdrop-bg: #333 !default;
$modal-backdrop-opacity: 0.35 !default;
$modal-header-border-color: #e5e5e5 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 15px !default;

$modal-xl: 1200px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$enable-shadows: true;
$enable-transitions: true;
$enable-rounded: true;

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    transition: $transition;
  }
}

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

// Kill the scroll on the body
.modalOpen {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: zIndex.$z-index-modal;
  display: block;
  overflow: hidden;
  pointer-events: auto !important; // need to explicitly allow pointer events in modals to counteract staff block drag & drop iframe issue fix
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  &Transparent {
    background-color: rgba(0,0,0,0.85);
  }
}

.modalOpen {
  pointer-events: none; // When a modal is open that uses drag and drop, make sure that only the modal can capture events. this
  // prevents iframes underneath from capturing events
}

.modalOpen .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.exitButton {
  color: white;
  font-size: 3rem;
}

// Shell div to position the modal with bottom padding
.modalDialog {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
  //overflow: scroll;
  word-wrap: break-word;
  @include mixins.translateTransition(translateY(-$modal-dialog-margin), translateY(calc(100%)));
  transform: translateY(-100%);

  &Transparent {
    max-width: 100%;
  }
}
// Close icon
.close {
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: $gray;
  text-shadow: 0 1px 0 #fff;
  position: absolute;
  right: 0.3em;
  top: 0.2em;
  z-index: 9999;
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;

  &Transparent {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 3rem;
    color: white;
    opacity: 1;
    font-weight: 100;
  }

  &Admin {
    opacity: 1;
    top: 0;
    line-height: 3rem !important;
    color: #fff;
    font-weight: 100;
    right: 0.5rem;
  }
}

// Actual modal
.modalContent {
  position: relative;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  // Remove focus outline from opened modal
  outline: 0;

  &Transparent {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none !important;
  }
}

// AdminModal background
.modalBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: zIndex.$z-index-modal-backdrop;
  background-color: $modal-backdrop-bg;
  opacity: 0;
  // TODO removing this because of a Chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=1330501&q=opacity&can=2 need to re-enable when fixed
  //transition: opacity 250ms ease;
}

// AdminModal header
// Top section of the modal w/ title and dismiss
.modalHeader {
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  @include clearfix;
}

// Title text within header
.modalTitle {
  margin: 0;
  text-align: center;
  line-height: $modal-title-line-height;
}

// AdminModal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modalBody {
  position: relative;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modalFooter {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
  @include clearfix(); // clear it in case folks use .pull-* classes on buttons
}

// Measure scrollbar width for padding body during modal show/hide
.modalScrollbarMeasure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include mixins.minScreenSm {
  // Automatically set modal's width for larger viewports
  .modalDialog {
    max-width: $modal-md;
    margin: $modal-dialog-sm-up-margin-y auto;
    @include mixins.translateTransition(translateY(-$modal-dialog-sm-up-margin-y), translateY(calc(100%)));
  }

  // AdminModal sizes
  .modalSm {
    max-width: $modal-sm;
  }
  .modalLg {
    max-width: $modal-lg;
  }
  .modalSchoolblocks {
    max-width: 600px;
  }
  .modalXl {
      max-width: 1600px;
  }
}

.modalContainer {
  transition: transform 250ms ease;
  overflow: hidden;
  @include box-shadow($modal-content-sm-up-box-shadow);
  @include border-radius($border-radius-lg);

  &Transparent {
    margin: 10% auto 0;
  }
}

.backdropContainer {
  @include mixins.opacityTransition(0, $modal-backdrop-opacity);
}

.backdropContainerNoOpacity {
  @include mixins.opacityTransition(0, 1);
}
