@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";
@use "../../../../styles/zIndex.module" as zIndex;

.mobileMenuContainer {
  @include mixins.maxHeightTransition(100%);

  .transitionEnterDone, .transitionExit {
    box-shadow: 2px 4px 6px 1px rgba(72, 72, 72, 0.5);
  }

  @media (min-width: $break-point-md) {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
  }

  z-index: zIndex.$z-index-mobile-nav-popup;

  @media (min-width: $break-point-lg) {
    display: none;
  }
}

.navContainer {
  display: block;
  max-width: 600px;
  width: 100%;
  height: 100%;
  bottom: 0px;
  max-height: 0;
  z-index: zIndex.$z-index-mobile-nav-popup;
  position: fixed;
  transition: max-height .5s ease;
  opacity: 1 !important;
  color: black;
  background-color: white;
  text-align: left;
  overflow-y: auto;
  border-radius:3px;
}

.menuContainer {
  background-color: white;
  padding-bottom: 50px;
  > ul > li > a, button {
    border-bottom: 1px lightgrey solid;
  }

  &IconAllEmpty {
    > ul > li > a > .metisMenuIcon,
    > ul > li > button > .metisMenuIcon {
      width: 0 !important;
    }
  }
}

.menuContainerVisible {
  display: block;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  margin-top: 2px;

  > li > a {
    visibility: visible !important;
  }
}

.menuItem {
  > ul {
    visibility: hidden;
  }
}

.hasVisibleChild {
  > a {
    background-color: rgb(232, 232, 233) !important;
    border: 1px solid var(--primaryNavBackgroundTextColor);
    border-radius: 5px;
    color: #000000 !important;
    text-shadow: none !important;
    margin-bottom: -2px;
  }
}

.link {
  text-shadow: none;
  width: 100%;
  text-align: left;
  color: #000000 !important;
  text-decoration: none !important;

  &:active,&:hover,&:focus {
    background-color: rgb(232, 232, 233) !important;
    border: 1px solid var(--primaryNavBackgroundTextColor);
    border-radius: 5px;
    color: #000000 !important;
  }
}

.activeLink {
  background-color: transparent !important;
  border: 1px solid var(--primaryNavBackgroundTextColor);
  border-radius: 5px;
  color: #000000 !important;
  text-shadow: none !important;
}

.activeLinkChild {
  margin-bottom: -2px;
}

.menuIcon {
  cursor: pointer;
  color: var(--primaryNavBackgroundTextColor);

  &:focus {
    @include mixins.focusOutline;
  }
}

.metisMenuIcon {
  cursor: pointer;
  margin-left: 10px;
  color: var(--blockBgTextColorNoBlocksUseSecondary);

  &:focus {
    @include mixins.focusOutline;
  }
}

.metislinkButton {
  color: var(--fontColor);
  padding-left: 10px;
  cursor: pointer;
  width: 100%;

  &:focus {
    @include mixins.focusOutline;
  }
}

.stateIcon {
  position: relative;
  margin-left: 5px;
  float: none !important;
  text-align: left !important;
  width: 0 !important;
  color: gray !important;
}

.exitContainer {
  font-size: 20px;
  text-align: right;
  padding: 10px 5px 10px 0px;
  color: #000000 !important;

  :hover {
    color: #4d4d4d !important;
  }
}

.mobileClose {
  padding: 0 5px;
  > span:nth-of-type(1) {
    font-size: 16px !important;
    padding-right: 10px;
    color: inherit;
  }
  > span:nth-of-type(2) {
    font-size: 25px;
  }
  span {
    line-height: 30px;
    vertical-align: middle;
  }
}

.search {
  float: left;
  color: black;
  padding-left: 10px;
  > span:nth-of-type(2) {
    font-size: 16px !important;
    padding-left: 10px;
  }
  span {
    line-height: 30px;
    vertical-align: middle;
  }
}

.shortcutList {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0px 0px 81px 0px;

  li {
    line-height: 15px;
    text-align: center;
    font-size: 1.2rem;
    padding: 10px 10px 10px 10px;
    flex-basis: 33.3333%;
    flex-grow: 1;

    button {
      span {
        position: relative;
        z-index: 1;
        font-size: 2rem;
      }
    }

    span {
      font-size: 4rem;
    }

    a {
      display: block;
      height: 100%;
      color: var(--blockBgTextColorNoBlocksUseSecondary) !important;

      div {
        margin-top: 15px;
        color: black;
      }
    }
  }

  &FourItems {
    li {
      flex-basis: 50%;
    }
  }
}
